import useOnboardingFlowForProgress from 'hooks/useOnboardingFlowForProgress';

let prevLastScreenId: string | undefined;

const useSectionedStepHeader = () => {
  const { currentScreen, onboardingFlow, progressConfig: config } = useOnboardingFlowForProgress();

  const currScreenIndex = onboardingFlow.findIndex(({ id }) => currentScreen.id === id);
  const restOnboardingFlow = onboardingFlow.slice(currScreenIndex + 1);

  let nextSectionIndex = config.sections.length;
  for (let i = 0; i < config.sections.length; i++) {
    if (restOnboardingFlow.some(({ id }) => config.sections[i].startScreenId.includes(id))) {
      nextSectionIndex = i || 1;
      break;
    }
  }

  const currSection = config.sections[nextSectionIndex - 1];
  const isModalScreen = prevLastScreenId === currentScreen.id;

  if (!isModalScreen) {
    prevLastScreenId = undefined;
  }

  const hasReachedSectionStartScreen =
    restOnboardingFlow.findIndex(({ id }) => currSection?.startScreenId.includes(id)) === -1;

  return {
    title: hasReachedSectionStartScreen ? currSection?.title : undefined,
    titleColor: hasReachedSectionStartScreen ? currSection?.titleColor : undefined,
  };
};

export default useSectionedStepHeader;
