import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { selectOnboardingConfig } from 'redux/Testania/selectors';

import { getConfig } from 'components/StepHeader/hooks/useSectionedStepHeader/config';

const useOnboardingFlowForProgress = () => {
  const { pathname } = useLocation();
  const onboardingFlow = useSelector(selectOnboardingConfig) || [];

  const branchName = localStorage.getItem('branch-name');
  const config = getConfig(branchName, onboardingFlow);
  const startScreenId = config.sections[0].startScreenId;

  const startScreenIndex = onboardingFlow.findIndex(({ id }) => startScreenId.includes(id));
  const lastScreenIndex = onboardingFlow.findIndex(({ id }) => id === config.lastScreenId);

  const trunkedOnboardingFlow = onboardingFlow.slice(startScreenIndex, lastScreenIndex + 1);

  const isABNewHeader = onboardingFlow.some(({ id }) => id.includes('_block_'));

  const currentLocationName = pathname.replace(/(\/([a-z]{2})\/)|\//g, '').replace(/-/g, '_');

  const currentScreen = useMemo(() => ({
    id: currentLocationName,
  }), [pathname]);


  return useMemo(() => {
    const index = trunkedOnboardingFlow.findIndex(({ id }) => id === currentScreen.id);
    const currentOnboardingStep = index >= 0 ? index + 1 : 0;
    const totalOnboardingSteps = trunkedOnboardingFlow.length;

    return {
      currentScreen,
      step: currentOnboardingStep,
      totalSteps: totalOnboardingSteps,
      isABNewHeader,
      onboardingFlow: trunkedOnboardingFlow,
      progressConfig: config,
    };
  }, [currentScreen, onboardingFlow]);
};

export default useOnboardingFlowForProgress;
