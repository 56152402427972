import axios from 'axios';

import config from 'config';

// constants
import { REQUEST_HEADERS } from '../constants';
import { DEFAULT_COUNTRY } from 'constants/countriesList';

// types
import { IApiClientMethodsProps } from './types/api';
import jwt_decode from 'jwt-decode';
import { deleteConfig, generateQueryParams } from 'helpers/utilsUpdated';
import { CURRENT_VERSION } from 'constants/versions';

const PLATFORM = 'front';

export default class ApiClient {
  onError: (error: any) => void;
  timezoneOffset: number;
  apiVersion: string;

  constructor({ onError }: IApiClientMethodsProps) {
    this.onError = onError;
    this.timezoneOffset = new Date().getTimezoneOffset() * 60;
    this.apiVersion = config.API_VERSION;
  }

  async get(url: any, params: any) {
    return this.request({
      url,
      params,
      method: 'GET',
      data: {},
    });
  }

  async post(url: any, payload = {}) {
    return this.request({
      url,
      method: 'POST',
      data: payload,
    });
  }

  async put(url: any, payload = {}) {
    return this.request({
      url,
      method: 'PUT',
      data: payload,
    });
  }

  async patch(url: any, payload = {}) {
    return this.request({
      url,
      method: 'PATCH',
      data: payload,
    });
  }

  async delete(url: any, payload = {}) {
    return this.request({
      url,
      method: 'DELETE',
      data: payload,
    });
  }

  isSandbox() {
    return REQUEST_HEADERS.sandbox;
  }

  async request(options: { url: any; params?: any; method?: string; data: any }) {
    const tmpOptions = {
      headers: undefined,
      ...options,
      url: `/${options.url}`,
    };

    const axiosInstance = axios.create({ baseURL: this.getApiUrl() });
    const token = this.getToken();
    this.setheaders(axiosInstance);
    const queryParams = generateQueryParams();

    if (!tmpOptions.params) {
      tmpOptions.params = {};
    }

    if (token) {
      // @ts-ignore
      tmpOptions.headers = {
        authorization: token,
      };
    }

    if (token) {
      const refreshToken = this.getRefreshToken();
      const decodedToken: any = jwt_decode(token);
      const currentDate = Date.now() / 1000;

      // For testing, token will expire after 1 min
      // if (decodedToken.exp - 3540 < currentDate) {
      if (decodedToken.exp < currentDate) {
        deleteConfig();

        if (location.search) history.pushState(null, '', `${location.origin}?${queryParams}`);

        location.reload();

        // delete axiosInstance.defaults.headers.common['authorization'];
        // const response = await axiosInstance.post('/generate-tokens', {
        //   refresh_token: refreshToken,
        // });
        // const { headers } = response;
        // console.log('TOKEN', headers.access_token);
        // localStorage.setItem('token', headers.access_token);
        // localStorage.setItem('refresh_token', headers.refresh_token);
        // axiosInstance.defaults.headers.common['authorization'] = this.getToken();
      }
    }


    if (this.isSandbox()) {
      try {
        tmpOptions.headers = {
          // @ts-ignore
          ...tmpOptions.headers,
          sandbox: true,
        };
      } catch (error) {
        console.error(error);
      }
    }

    tmpOptions.headers = {
      // @ts-ignore
      ...tmpOptions.headers,
      'Content-Type': 'application/json',
      'version': this.apiVersion,
    };

    let response = {};

    try {
      // @ts-ignore
      response = await axiosInstance(tmpOptions);
      // @ts-ignore
      if (response.headers.authorization) response.data.token = response.headers.token;
      // @ts-ignore
      return Promise.resolve(response.data);
    } catch (error) {
      this.onError({ error, tmpOptions });

      console.error('Server Error', error.response.data);

      return Promise.reject(error.response.data);
    }
  }

  getUUID() {
    return localStorage.getItem('uuid') || '';
  }

  private getApiUrl() {
    return REQUEST_HEADERS.apiUrl;
  }

  private getLocalization() {
    return localStorage.getItem('language') || REQUEST_HEADERS.language;
  }

  private getCountry() {
    return localStorage.getItem('country') || DEFAULT_COUNTRY;
  }


  private getApiKey() {
    return REQUEST_HEADERS.apiKey;
  }

  private getApiVersion() {
    return REQUEST_HEADERS.apiVersion;
  }

  private getToken() {
    const token = localStorage.getItem('token');
    return token || REQUEST_HEADERS.authorization;
  }

  private setheaders(axiosInstance: any) {
    axiosInstance.defaults.headers.common['timezone-offset'] = this.timezoneOffset;
    axiosInstance.defaults.headers.common['version'] = this.getApiVersion();
    axiosInstance.defaults.headers.common['x-api-key'] = this.getApiKey();
    axiosInstance.defaults.headers.common['language'] = this.getLocalization();
    axiosInstance.defaults.headers.common['platform'] = PLATFORM;
    axiosInstance.defaults.headers.common['client-version'] = CURRENT_VERSION;
    axiosInstance.defaults.headers.common['locale'] = `${this.getLocalization()}_${this.getCountry()}`;
    axiosInstance.defaults.headers.common['uuid'] = this.getUUID();
    axiosInstance.defaults.headers.common['debug'] = config.ENV !== 'prod';
  }

  private getRefreshToken() {
    return localStorage.getItem('refresh_token') || '';
  }
}
