/* eslint-disable max-lines */
import { Main, SYSTEMS_NAMES } from 'wikr-core-analytics';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { ERROR_LEVELS, SENTRY_PAYMENT } from '../../../constants/sentry';

// eslint-disable-next-line import-helpers/order-imports
import { selectUserId } from 'redux/User/selectors';


// import { showModal } from 'redux/UiEffects/actions';
import { selectFlowLink, selectTestaniaName } from 'redux/Testania/selectors';
import { RootState } from 'redux/rootReducer';
import {
  selectCheckoutOrderId,
  selectCurrency,
  selectCurrentProduct,
  selectCurrentProductCode,
  selectOneClickPaymentPrice,
  selectPaymentMethod,
  selectResubModalConfig,
  selectUpsellId,
} from 'redux/Payment/selectors';
import * as actionTypes from 'redux/Payment/actionTypes';
import { initOneClickPayment as initOneClickPaymentAction } from 'redux/Payment/actions';

import { PAYMENT_TYPES_NAME, PAYMENT_TYPES_NAME_ID } from 'constants/payments';

import Solid from 'services/SolidSDK';
import sentry from 'services/Sentry';
import { GoogleAds } from 'services/Analytics/Systems/Google';

import {
  cleanObject,
  deleteSlash,
  fromPennyToInt,
  generateQueryParams,
  getPriceFromCents,
  replaceToUnderscore,
} from 'helpers/utilsUpdated';
import { getPaymentIdByMethod, isLifetime, isSubscription } from 'helpers/payments';

import { ValidateResponse } from 'types/payments/validate';
import { Currency, CurrentProduct, PaymentMethod, ProductCode, ToNextPagePayload } from 'types/payments/payments';
import { PaymentData } from 'types/payments/paymentApiInterfaces';
import { AnalyticPurchasePayload, IValidateRecurringPayment } from 'types/payments/oneClickPayment';

const CORE_SYSTEMS_UPSELLS = ['upsell_sex', 'upsell_pregnancy', 'upsell'];


const getOneClickPrice = (state: RootState) => selectOneClickPaymentPrice(state);
const getCurrency = (state: RootState) => selectCurrency(state);
const getOrderId = (state: RootState) => selectCheckoutOrderId(state);
const getPaymentMethod = (state: RootState) => selectPaymentMethod(state);
const getUserId = (state: RootState) => selectUserId(state);
const getCurrentProductCode = (state: RootState) => selectCurrentProductCode(state);
const getCurrentProduct = (state: RootState) => selectCurrentProduct(state);
const getFlowLink = (state: RootState) => selectFlowLink(state);
const getTestaniaName = (state: RootState) => selectTestaniaName(state);
const getUpsellId = (state: RootState) => selectUpsellId(state);
const getResubModalConfig = (state: RootState) => selectResubModalConfig(state);

function* init({ payload }: ReturnType<typeof initOneClickPaymentAction>) {
  const oneClickPrice: number = yield select(getOneClickPrice);
  const checkoutOrderId: number = yield select(getOrderId);
  const currentProduct: CurrentProduct = yield select(getCurrentProduct);
  const paymentMethod: PaymentMethod = yield select(getPaymentMethod);
  const currentProductCode: ProductCode = yield select(getCurrentProductCode);
  const currency: Currency = yield select(getCurrency);
  const userId: number = yield select(getUserId);

  const paymentType = currentProduct?.payment_type;
  const paymentSystem: string = PAYMENT_TYPES_NAME_ID[paymentMethod];

  try {
    yield put({ type: actionTypes.SET_LOADING_STATUS, payload: true });

    const meta = {
      product_code: currentProductCode,
      ...(isLifetime(paymentType) && { price: oneClickPrice }),
      ...(isSubscription(paymentType) && { product_id: currentProduct?.id }),
    };

    // @ts-ignore
    const upsellResponse: PaymentData = yield Solid.oneClickPayment(paymentSystem, meta);

    const responseOrder = upsellResponse?.order;

    const paymentId =
      isSubscription(paymentType) && responseOrder?.subscription_id
        ? responseOrder?.subscription_id
        : responseOrder?.order_id;

    const payloadMeta = {
      currentProductCode,
      checkoutOrderId,
      paymentMethod,
      currentProduct,
      oneClickPrice,
      currency,
      userId,
    };
    const validateMeta = {
      paymentId,
      payload,
      orderId: responseOrder?.order_id,
      subscriptionId: responseOrder?.subscription_id || '',
    };

    yield put({ type: actionTypes.SET_PAYMENT_DATA, payload: upsellResponse });
    yield call(validateRecurringPayment, { validateMeta, payloadMeta, responseOrder });
  } catch (error) {
    console.error('init oneClickPayment error', error);

    sentry.logError(error, SENTRY_PAYMENT, ERROR_LEVELS.CRITICAL, {
      ...currentProduct,
      ...payload,
    });

    payload.onError();

    // yield call(moveToNextPage, true, payload);
    yield call(errorHandler);
  } finally {
    yield put({ type: actionTypes.SET_LOADING_STATUS, payload: false });
  }
}

function* validateRecurringPayment({ validateMeta, payloadMeta, responseOrder }: IValidateRecurringPayment) {
  const currentProductCode = payloadMeta.currentProductCode;
  const checkoutOrderId: number = payloadMeta.checkoutOrderId;
  const paymentMethod: PaymentMethod = payloadMeta.paymentMethod;
  const currentProduct: CurrentProduct = payloadMeta.currentProduct;

  const paymentSystem: string = PAYMENT_TYPES_NAME_ID[paymentMethod];
  const cardBrand: string = localStorage.getItem('cardBrand') || '';

  const flow_link: string = yield select(getFlowLink);
  const testaniaName: string = yield select(getTestaniaName);
  // @ts-ignore
  const resubModalConfig = yield select(getResubModalConfig);

  const paymentMethodId: number = getPaymentIdByMethod(paymentMethod);
  const ltvValue = Number(getPriceFromCents(currentProduct?.ltv));

  const paymentType = currentProduct?.payment_type;
  const pageUrl = deleteSlash(validateMeta?.payload?.screenId);
  const screenId = replaceToUnderscore(pageUrl);

  const isSubscriptionType = isSubscription(paymentType);

  const meta = {
    ...(!flow_link.includes('localhost') && { flow_link }),
    payment_method: paymentMethodId,
    payment_type: paymentType,
    product_code: currentProductCode,
    ...(isSubscriptionType && {
      subscription_value: {
        id: responseOrder.subscription_id,
        expired_at: new Date(),
        subscription_period: payloadMeta.currentProduct?.period,
        subscription_trial_period: payloadMeta.currentProduct?.trial,
      },
    }),
    ...(testaniaName && { ab_test_name: testaniaName }),
    ...(pageUrl && { payment_screen: pageUrl }),
    order_value: responseOrder,
    parent_order_id: checkoutOrderId, // order id from checkout page
  };

  try {
    let response: ValidateResponse;

    if (resubModalConfig?.isOpen) {
      response = yield Solid.validateResubscribe(paymentSystem, meta);
    } else {
      response = yield Solid.validate(paymentSystem, meta);
    }

    if (response.error) {
      // yield call(showErrorModal, true);
      yield call(setUpsellId, currentProductCode);
    }

    const data: AnalyticPurchasePayload = {
      value: ltvValue,
      order_id: validateMeta.orderId,
      paymentMethodId,
      // TODO: is not written to validateMeta, maybe it should be removed.
      cardBrand,
      customId: screenId,
      paymentType,
      oneClickPrice: payloadMeta.oneClickPrice,
      tariff: payloadMeta.currentProduct?.id,
      subscriptionId: validateMeta.paymentId,
      isTrial: !!currentProduct?.trial,
      amount: currentProduct.price,
      subscriptionPrice: currentProduct?.start_price,
      isResubscriptionFlow: resubModalConfig?.isOpen,
      currentProductCode,
    };

    if (!response.error) {
      yield call(sendAnalyticPurchase, data);
      yield put({ type: actionTypes.VALIDATE_PAYMENT_DATA, payload: { ...response, result: true } });
      yield call(setUpsellId, currentProductCode);
      yield call(moveToNextPage, true, validateMeta.payload);
    }
  } catch (e) {
    console.error('validate oneClickPayment error', e);

    sentry.logError(e.error, SENTRY_PAYMENT, ERROR_LEVELS.CRITICAL, {
      ...currentProduct,
      ...meta,
    });

    yield call(moveToNextPage, true, validateMeta.payload);
    yield call(errorHandler);
  }
}

function* sendAnalyticPurchase(payload: AnalyticPurchasePayload) {
  try {
    const currency: Currency = yield select(getCurrency);
    const userId: number = yield select(getUserId);
    const upsellId: string = yield select(getUpsellId);
    const paymentMethod: PaymentMethod = yield select(getPaymentMethod);

    const cents: number = payload.oneClickPrice;
    const price: number = fromPennyToInt(cents);
    const paymentMethodName: string | null = PAYMENT_TYPES_NAME[paymentMethod] || null;

    const abTestName: string | null = localStorage.getItem('testania_name');
    const urlParams: string = generateQueryParams();

    const { value, order_id, tariff, paymentType, cardBrand, isResubscriptionFlow, currentProductCode = '' } = payload;

    localStorage.setItem('paymentType', paymentType || 'lifetime');

    const analyticProductName = isLifetime(paymentType) ? ` ${upsellId}` : '';

    const isTrial = payload.isTrial;

    const content_id = isTrial
      ? `Product - Introductory Price ${fromPennyToInt(payload.amount)} - Subscription price ${fromPennyToInt(
        payload.subscriptionPrice,
      )}`
      : `Product - Price ${price}${analyticProductName}`;

    const analyticData = {
      currency: currency.name,
      value,
      content_id,
      tariff,
      price,
      payment: paymentMethodName,
      card_type: cardBrand,
      order_id: order_id,
      screen_id: payload?.customId,
      ab_test_name: abTestName,
      user_id: userId,
      urlParams,
      order_type: paymentType,
      ...(isTrial && {
        subscription_price: fromPennyToInt(payload.subscriptionPrice),
        subscription_id: payload.subscriptionId,
      }),
    };

    const gaData = {
      value,
      currency,
      order_id,
    };

    const isSendCoreSystems = isResubscriptionFlow || CORE_SYSTEMS_UPSELLS.includes(currentProductCode);

    /* Remove event purchase from resubscribe flow */
    const coreSystems = [SYSTEMS_NAMES.amazon, SYSTEMS_NAMES.pinterest];
    const activeSystems = [...coreSystems, SYSTEMS_NAMES.pixel];

    Main.purchase(cleanObject(analyticData), {
      selectedSystems: isSendCoreSystems ? coreSystems : activeSystems,
    });
    GoogleAds.purchase(gaData);
  } catch (e) {
    console.error('sendAnalyticPurchase', e);
  }
}

function* moveToNextPage(validateStatus: boolean, payload: ToNextPagePayload) {
  yield call(payload.toNextPage, validateStatus);
}

// function* showErrorModal(isOpen: boolean) {
//   yield put(showModal(isOpen));
// }

function* errorHandler() {
  yield call(setUpsellId, '100');
}

function* setUpsellId(id: string) {
  yield put({ type: actionTypes.SET_UPSELL_PRODUCT_ID, payload: id });
}

export const initOneClickPayment = [takeLatest(actionTypes.INIT_ONE_CLICK_PAYMENT, init)];
